// React
import React, { useEffect, useState } from 'react';

// Packages
import t from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import Link from 'next/link';
import Image from 'next/legacy/image';
import dayjs from 'dayjs';

// Utilities
import { getPermissionRules, sortTags } from 'utilities';
import {
    useElseware,
    useLabels,
    usePermissions,
    useUser,
} from 'utilities/hooks';
import { useInitiativeDataStore } from 'utilities/store';
import { ACCOUNTS } from 'utilities/configuration/permission';

// Components
import WithAuth from 'components/withAuth';
import Preloader from 'components/preloader';
import PageTop from 'components/_layout/pageTop';
import InitiativeRow from 'components/initiativeRow';
import {
    SearchFilterMultiselect,
    SearchFilterDate,
    SearchFilterSearchMultiselect,
} from 'components/_inputs';

const HomeComponent = () => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { reset } = useInitiativeDataStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label, pickList, tagLabel } = useLabels();
    const { ewGet, ewGetWithPagination } = useElseware();
    const { enableAction } = usePermissions();
    const { getUserAccountType, getUserAccountId } = useUser();

    // ///////////////////
    // STATE
    // ///////////////////

    const [tagsPath, setTagsPath] = useState(null);
    const [funders, setFunders] = useState(null);
    const [initial, setInitial] = useState(null);
    const [filtered, setFiltered] = useState(null);
    const [tagOptions, setTagOptions] = useState([]);

    // ///////////////////
    // FORMS
    // ///////////////////

    const { control, register, getValues } = useForm({
        mode: 'onChange',
    });
    const filterText = useWatch({
        control,
        name: 'filter.text',
    });
    const filterCategory = useWatch({
        control,
        name: 'filter.category',
    });
    const filterStartDate = useWatch({
        control,
        name: 'filter.startDate',
    });
    const filterEndDate = useWatch({
        control,
        name: 'filter.endDate',
    });
    const filterTags = useWatch({
        control,
        name: 'filter.tags',
    });

    // ///////////////////
    // METHODS
    // ///////////////////

    function onFilter(data) {
        if (initial) {
            const {
                filter: { tags, text, category, startDate, endDate },
            } = data;
            let nextFiltered;
            const flattenedTags = tags.flat();

            // Optional text
            nextFiltered = text
                ? initial.filter(item => {
                      const fundingRecipientsString = item._fundingRecipients
                          ?.map(fr => fr?.Account__r?.Name.toLowerCase())
                          .join(':');

                      return (
                          item.Name?.toLowerCase().includes(
                              text.toLowerCase()
                          ) ||
                          fundingRecipientsString.includes(
                              text.toLowerCase()
                          ) ||
                          item._applicationIds.filter(id =>
                              id.includes(text.toLowerCase())
                          ).length > 0
                      );
                  })
                : initial;

            // Optional category
            nextFiltered =
                category.length > 0
                    ? nextFiltered.filter(item =>
                          category.includes(item.Category__c)
                      )
                    : nextFiltered;

            // Optional start date
            nextFiltered = startDate
                ? nextFiltered.filter(item => {
                      // Check if grantStart happens AFTER filterStart
                      const grantStart = dayjs(item.Grant_Start_Date__c);
                      const filterStart = dayjs(startDate).format('YYYY-MM-DD');
                      return grantStart.diff(filterStart) >= 0;
                  })
                : nextFiltered;

            // Optional end date
            nextFiltered = endDate
                ? nextFiltered.filter(item => {
                      // Check if grantEnd happens BEFORE filterEnd
                      const grantEnd = dayjs(item.Grant_End_Date__c);
                      const filterEnd = dayjs(endDate).format('YYYY-MM-DD');
                      return grantEnd.diff(filterEnd) <= 0;
                  })
                : nextFiltered;

            // Optional no tags
            nextFiltered = flattenedTags.includes('NO_TAGS')
                ? nextFiltered.filter(item => item._initiativeTags?.length < 1)
                : nextFiltered;

            // Optional tags
            nextFiltered =
                flattenedTags.length > 0 && !flattenedTags.includes('NO_TAGS')
                    ? nextFiltered.filter(item => {
                          const itemTags = item._initiativeTags?.map(
                              x => x.Tag__c
                          );
                          return flattenedTags.some(f => itemTags?.includes(f));
                      })
                    : nextFiltered;

            setFiltered(nextFiltered);
        }
    }

    // ///////////////////
    // DATA
    // ///////////////////

    const { data: initiativesData } = ewGetWithPagination(
        'initiative/initiatives-overview'
    );
    const { data: tags } = ewGet(
        tagsPath,
        {
            funders,
        },
        funders && tagsPath
    );

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (initiativesData?.data) {
            const userAccountType = getUserAccountType();
            if (userAccountType === ACCOUNTS.super) {
                setTagsPath('tag/all-tags');
                setFunders([]);
            }
            if (userAccountType === ACCOUNTS.funder) {
                setTagsPath('tag/all-funder-tags-multiple');
                setFunders([getUserAccountId()]);
            }
            if (userAccountType === ACCOUNTS.grantee) {
                setTagsPath('tag/all-funder-tags-multiple');
                setFunders(
                    Array.from(
                        new Set([
                            ...initiativesData?.data
                                ?.map(item =>
                                    item._funders.map(x => x.Account__c)
                                )
                                .flat(),
                        ])
                    )
                );
            }
        }
    }, [getUserAccountType(), initiativesData]);

    // Add data results to initial data set
    useEffect(() => {
        if (initiativesData?.data) {
            // Make sure initiative.Id is unique
            const uniqueInitiatives = Array.from(
                new Set(initiativesData.data.map(a => a.Id))
            ).map(id => {
                return initiativesData.data.find(a => a.Id === id);
            });

            setInitial(uniqueInitiatives);
            setFiltered(uniqueInitiatives);
        }
    }, [initiativesData]);

    useEffect(() => {
        if (tags?.data) {
            // Sort tags
            const sortedTags = sortTags(Object.values(tags?.data));
            // Make array of tags with the format { value: [tagIds], label: 'tagLabel'}
            // If a tag label exists more than one, the value should be added to the array of tagIds
            const tagIds = {};
            sortedTags
                .filter(tag => tag.Type__c === 'Initiative')
                .forEach(tag => {
                    const tagName = tagLabel(tag);
                    if (tagIds[tagName]) {
                        tagIds[tagName].push(tag.Id);
                    } else {
                        tagIds[tagName] = [tag.Id];
                    }
                });
            setTagOptions([
                { value: 'NO_TAGS', label: label('OverviewNoTagsSet') },
                ...Object.entries(tagIds).map(([key, value]) => ({
                    value,
                    label: key,
                })),
            ]);
        }
    }, [tags]);

    useEffect(() => {
        onFilter(getValues());
    }, [
        filterCategory,
        filterText,
        filterStartDate,
        filterEndDate,
        filterTags,
    ]);

    useEffect(() => {
        reset();
    }, []);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <div className="flex flex-col mb-48 animate-fade-in">
                <PageTop
                    {...{
                        heading: label('InitiativeManagerHeading'),
                        methods: {
                            primary: {
                                action: enableAction(
                                    getPermissionRules(
                                        'create',
                                        'nameAndCategory',
                                        'add'
                                    ),
                                    '/create/create-start'
                                ),
                                label: label('InitiativeManagerCreate'),
                            },
                        },
                    }}
                />
                <div className="flex flex-col">
                    <input
                        {...register('filter.text')}
                        type="text"
                        placeholder={label('InitiativeManagerSearchBoxText')}
                        className="input-search"
                    />
                    <div className="flex flex-wrap mt-16 -m-8">
                        <SearchFilterSearchMultiselect
                            name="filter.tags"
                            label={label('InitiativeManagerFilterFilterTags')}
                            controller={control}
                            options={tagOptions}
                            sort={false}
                        />
                        <SearchFilterMultiselect
                            name="filter.category"
                            label={label(
                                'InitiativeManagerFilterFilterGrantGivingArea'
                            )}
                            controller={control}
                            options={pickList('Initiative__c.Category__c')}
                        />
                        <SearchFilterDate
                            name="filter.startDate"
                            label={label(
                                'InitiativeManagerFilterGrantStartDate'
                            )}
                            controller={control}
                        />
                        <SearchFilterDate
                            name="filter.endDate"
                            label={label('InitiativeManagerFilterGrantEndDate')}
                            controller={control}
                        />
                    </div>
                </div>
            </div>

            {initiativesData && filtered ? (
                <div className="flex flex-col pb-64 space-y-12">
                    {/* Initiatives and Results are there */}
                    {initiativesData?.data.length > 0 &&
                        filtered.length > 0 &&
                        filtered?.map(item => (
                            <div key={item.Id} className=" animate-fade-in">
                                <InitiativeRowWithContent {...{ item }} />
                            </div>
                        ))}

                    {/* Initiatives are there but no results */}
                    {initiativesData?.data.length > 0 && filtered?.length < 1 && (
                        <EmptyState
                            {...{
                                heading: label(
                                    'InitiativesOverviewNoResultsHeading'
                                ),
                                body: label('InitiativesOverviewNoResultsBody'),
                            }}
                        />
                    )}

                    {/* No initiatives */}
                    {initiativesData?.data.length < 1 && (
                        <EmptyState
                            {...{
                                heading: label(
                                    'InitiativesOverviewNoInitiativesHeading'
                                ),
                                body: label(
                                    'InitiativesOverviewNoInitiativesBody'
                                ),
                            }}
                        />
                    )}
                </div>
            ) : (
                <Preloader />
            )}
        </>
    );
};

const InitiativeRowWithContent = ({ item }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { getUserAccountType, getUserAccountId } = useUser();

    // ///////////////////
    // STATE
    // ///////////////////

    const [reports, setReports] = useState(item?._reports);

    // ///////////////////
    // DATA
    // ///////////////////

    const hasAccess = item?.UserRecordAccess?.HasReadAccess ?? false;

    // If funder, then only get reports where funder has same Initiative_Funding__c = Report.Funding_Report__c
    useEffect(() => {
        if (getUserAccountType() === ACCOUNTS.funder) {
            const currentFunderId = getUserAccountId();
            const fundersFundings = item?._funders
                .filter(funder => funder.Account__c === currentFunderId)
                .map(funder => funder.Initiative_Funding__c);
            const reportsFromFunder = item?._reports.filter(report =>
                fundersFundings.includes(report.Funding_Report__c)
            );
            setReports(reportsFromFunder);
        } else {
            setReports(item?._reports);
        }
    }, [item, getUserAccountType()]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return hasAccess ? (
        <Link href={`/${item?.Id}/overview`} className="block">
            <InitiativeRow
                {...{
                    item,
                    reports,
                    fundingRecipients: item?._fundingRecipients,
                    funders: item?._funders,
                }}
            />
        </Link>
    ) : (
        <InitiativeRow
            {...{
                item,
                reports,
                fundingRecipients: item?._fundingRecipients,
                funders: item?._funders,
                limitedAccess: true,
            }}
        />
    );
};

const EmptyState = ({ heading, body }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col items-center justify-center space-y-32 animate-fade-in !pt-48">
            <div className="relative h-[192px] w-full">
                <Image
                    layout="fill"
                    objectFit="contain"
                    src="https://www.datocms-assets.com/69268/1665992793-initiatives-reports-empty-state.png"
                />
            </div>
            <div className="flex flex-col space-y-16 text-center">
                <p className="text-blue-100 t-h6">{heading}</p>
                <p className="text-blue-100 t-caption">{body}</p>
            </div>
        </div>
    );
};

HomeComponent.propTypes = {
    pageProps: t.object,
};

HomeComponent.defaultProps = {
    pageProps: {},
};

HomeComponent.layout = 'default';

export default WithAuth(HomeComponent);
